import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { PropertyInsuranceFormValues } from '../../types/propertyInsurance';
import { TripInsuranceFormValues } from '../../types/tripInsurance';
import { CarInsuranceFormValues } from '../../types/carInsurance';

export default class LeadStore {
  constructor() {
    makeAutoObservable(this);
  }
  uploadLeadFile = async (formData: FormData) => {
    return agent.Lead.uploadFile(formData);
  };
  sendPropertyInsuranceLead = async (request: PropertyInsuranceFormValues) => {
    return agent.Lead.sendPropertyInsuranceLead(request);
  };
  sendTripInsuranceLead = async (request: TripInsuranceFormValues) => {
    return agent.Lead.sendTripInsuranceLead(request);
  };
  sendCarInsuranceLead = async (request: CarInsuranceFormValues) => {
    return agent.Lead.sendCarInsuranceLead(request);
  };
}
