import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { PropertyInsuranceFormValues } from '../../../types/propertyInsurance';
import { OptionValues } from '../../FormControls/MyRadioButton/types';

export const propertyRoofTypeOptions: OptionValues[] = [
  { value: 'dachówka ceramiczna/cementowa', label: 'dachówka ceramiczna/cementowa' },
  { value: 'eternit', label: 'eternit' },
  { value: 'papa/bitumiczne/ondulina', label: 'papa/bitumiczne/ondulina' },
  { value: 'blacha/blachodachówka', label: 'blacha/blachodachówka' },
  { value: 'drewno/drewnopodobne', label: 'drewno/drewnopodobne' },
  { value: 'inne tworzywa/materiały sztuczne', label: 'inne tworzywa/materiały sztuczne' },
  { value: 'trzcina/słoma', label: 'trzcina/słoma' },
];

export const propertyWallTypeOptions: OptionValues[] = [
  { value: 'Murowane', label: 'Murowane' },
  { value: 'Drewniane', label: 'Drewniane' },
];

export const photoVoltaicInstallationPlaceOptions: OptionValues[] = [
  { value: 'dach budynku mieszkalnego', label: 'dach budynku mieszkalnego' },
  { value: 'dach budynku gospodarczego', label: 'dach budynku gospodarczego' },
  { value: 'ziemia', label: 'ziemia' },
];

export const initialValues: PropertyInsuranceFormValues = {
  campaignType: 'property',
  campaignTypeHumanName: 'Ubezpieczenie majątkowe',
  insuranceStartYear: undefined,
  insuranceStartMonth: undefined,
  insuranceStartDay: undefined,
  firstName: '',
  lastName: '',
  pesel: '',
  email: '',
  phone: '',
  isCoOwner: false,
  coOwnerFirstName: '',
  coOwnerLastName: '',
  coOwnerPesel: '',
  coOwnerEmail: '',
  coOwnerPhone: '',
  propertyAddressStreet: '',
  propertyAddressHouseNumber: '',
  propertyAddressFlatNumber: '',
  propertyAddressPostCode: '',
  propertyAddressPlace: '',
  propertyCommissioningYear: undefined,
  propertyCommissioningMonth: undefined,
  propertyCommissioningDay: undefined,
  propertySize: undefined,
  propertyNumberOfFloors: undefined,
  isBusinessActivity: undefined,
  pkdCode: '',
  isForRent: undefined,
  fileId: undefined,
  insurancePropertyAccidentalEvents: false,
  insurancePropertyTheft: false,
  insuranceLiabilityInPrivateLife: false,
  insuranceAssistance: false,
  insuranceGlassBreakage: false,
  breakInResidence: undefined,
  buildingWalls: '',
  buildingRoof: '',
  photoVoltaicInstallation: undefined,
  valueOfPhotoVoltaicInstallation: '',
  placeOfPhotoVoltaicInstallation: '',
  heatPumpInstallation: undefined,
  valueOfHeatPumpInstallation: '',
  comments: '',
};
export const validationSchema = Yup.object().shape({
  campaignType: Yup.string().required('Wymagany jest rodzaj kampanii'),
  insuranceStartYear: Yup.number().required('Wymagany jest rok rozpoczęcia ubezpieczenia'),
  insuranceStartMonth: Yup.number().required('Wymagany jest miesiąc rozpoczęcia ubezpieczenia'),
  insuranceStartDay: Yup.number().required('Wymagany jest dzień rozpoczęcia ubezpieczenia'),
  firstName: Yup.string().required('Wymagane jest imię'),
  lastName: Yup.string().required('Wymagane jest nazwisko'),
  pesel: Yup.string()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
  email: Yup.string().email('Nieprawidłowy adres email').required('Wymagany jest adres email'),
  phone: Yup.string().matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu').required('Pole jest wymagane'),
  isCoOwner: Yup.boolean(),
  coOwnerFirstName: Yup.string().when('isCoOwner', {
    is: true,
    then: Yup.string().required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
  coOwnerLastName: Yup.string().when('isCoOwner', {
    is: true,
    then: Yup.string().required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
  coOwnerPesel: Yup.string().when('isCoOwner', {
    is: true,
    then: Yup.string()
      .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
      .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
    otherwise: Yup.string(),
  }),
  coOwnerEmail: Yup.string().when('isCoOwner', {
    is: true,
    then: Yup.string().email('Nieprawidłowy adres email'),
    otherwise: Yup.string(),
  }),
  coOwnerPhone: Yup.string().when('isCoOwner', {
    is: true,
    then: Yup.string().matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu').required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
  propertyAddressStreet: Yup.string().required('Wymagana jest ulica nieruchomości'),
  propertyAddressHouseNumber: Yup.string().required('Wymagany jest numer domu nieruchomości'),
  propertyAddressFlatNumber: Yup.string(),
  propertyAddressPostCode: Yup.string().required('Wymagany jest kod pocztowy nieruchomości'),
  propertyAddressPlace: Yup.string().required('Wymagana jest miejscowość nieruchomości'),
  propertyCommissioningYear: Yup.number(),
  propertyCommissioningMonth: Yup.number(),
  propertyCommissioningDay: Yup.number(),
  propertySize: Yup.number().required('Wymagany jest rozmiar nieruchomości'),
  isBusinessActivity: Yup.boolean(),
  pkdCode: Yup.string().when('isBusinessActivity', {
    is: true,
    then: Yup.string().required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
  isForRent: Yup.boolean().required('Wymagana jest informacja o wynajmie'),
  fileId: Yup.number(),
  insurancePropertyAccidentalEvents: Yup.boolean(),
  insurancePropertyTheft: Yup.boolean(),
  insuranceLiabilityInPrivateLife: Yup.boolean(),
  insuranceAssistance: Yup.boolean(),
  insuranceGlassBreakage: Yup.boolean(),
});
export const validationSchemaWithFile = Yup.object().shape({
  campaignType: Yup.string().required('Wymagany jest rodzaj kampanii'),
  fileId: Yup.number(),
  insurancePropertyAccidentalEvents: Yup.boolean(),
  insurancePropertyTheft: Yup.boolean(),
  insuranceLiabilityInPrivateLife: Yup.boolean(),
  insuranceAssistance: Yup.boolean(),
  insuranceGlassBreakage: Yup.boolean(),
});
