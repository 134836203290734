import { useField } from 'formik';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { MySelectInputOptions, MySelectInputProps } from './types';
import Select, { SingleValue, StylesConfig } from 'react-select';
import { Color } from '../../../constants/Color';

export default function MySelectInput(props: MySelectInputProps) {
  const [, { error, touched }, helpers] = useField(props.name);

  const defaultValue = () => {
    return props.options ? props.options.find((option) => option.value === props.value) ?? null : '';
  };

  type IsMulti = false;

  const selectStyle: StylesConfig<string | MySelectInputOptions, IsMulti> = {
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: '0.75rem;',
        cursor: 'pointer',
        borderWidth: 0,
        outline: state.isFocused ? Color.GREEN_PRIMARY : error ? Color.RED_WARNING : 'none',
        boxShadow: state.isFocused
          ? error
            ? `inset 0 0 0 2px rgba(239, 68, 68, 1)`
            : `inset 0 0 0 2px rgba(156, 163, 175, 1)`
          : error
            ? `inset 0 0 0 1px rgba(239, 68, 68, 1)`
            : `inset 0 0 0 1px rgba(209, 213, 219, 1)`, // tailwind tw-ring-1 tw-ring-inset tw-ring-gray-300 and focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400
        '&:hover': {
          borderColor: touched && error ? '#EF5350' : '#c7d3d0',
        },
        '*': {
          boxShadow: 'none !important',
        },
        ...props.style,
      };
    },
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      background: state.isSelected
        ? Color.GREEN_PRIMARY
        : state.isFocused
          ? 'rgba(0,121,107,0.1)'
          : provided.background,
      ...props.style,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: '10000',
    }),
  };

  const selectHandler = (selectedFromPlace: SingleValue<string | MySelectInputOptions>): void => {
    const selectedValue = (selectedFromPlace as MySelectInputOptions)?.value;
    helpers.setValue(selectedValue);

    if (props.onChange != null) {
      props.onChange(selectedValue);
    }
  };

  return (
    <Form.Group className={props.containerClassName}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Select
        className={props.className}
        isClearable={props.isClearable}
        isDisabled={props.disabled}
        isLoading={props.isLoading}
        loadingMessage={() => 'Ładowanie...'}
        menuPortalTarget={props.bodyPortal ? document.body : null}
        menuPosition={props.bodyPortal ? 'fixed' : 'absolute'}
        name={props.name}
        noOptionsMessage={() => 'Brak pozycji do wyboru'}
        onBlur={() => helpers.setTouched(true)}
        onChange={selectHandler}
        onInputChange={props.onInputChange}
        options={props.options}
        placeholder={props.placeholder}
        styles={selectStyle}
        value={defaultValue()}
      />

      <div
        className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200  ${
          error ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
        }`}>
        <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600' id='email-error'>
          {error} &nbsp; {/*&nbsp; is necessary for animation */}
        </p>
      </div>
    </Form.Group>
  );
}
